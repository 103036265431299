import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {BrokerFilterComponent} from './components/broker-filter/broker-filter.component';
import {CommissionFileItemComponent} from './components/commission-file-item/commission-file-item.component';
import {CommissionFilesComponent} from './components/commission-files/commission-files.component';
import {CommissionStatementsComponent} from './components/commission-statements/commission-statements.component';
import {
  CommissionStatementItemComponent
} from './components/commission-statement-item/commission-statement-item.component';
import {ResourceFilterComponent} from './components/resource-filter/resource-filter.component';
import {RecoveriesComponent} from "./components/recoveries/recoveries.component";
import {RecoveryItemComponent} from "./components/recovery-items/recovery-item.component";
import {PaymentFilesComponent} from "./components/payment-files/payment-files.component";
import {PaymentFileItemComponent} from "./components/payment-file-item/payment-file-item.component";
import {
  CommissionFileOverviewComponent
} from "./components/commission-file-overview/commission-file-overview.component";
import {TextFilterComponent} from "./components/text-filter/text-filter.component";
import {FlagFilterComponent} from "./components/app-flag-filter/flag-filter.component";

@NgModule({
  imports: [SharedModule, TranslateModule],
  declarations: [
    BrokerFilterComponent,
    TextFilterComponent,
    FlagFilterComponent,
    CommissionFileItemComponent,
    CommissionFilesComponent,
    PaymentFilesComponent,
    RecoveriesComponent,
    RecoveryItemComponent,
    CommissionStatementsComponent,
    CommissionStatementItemComponent,
    PaymentFileItemComponent,
    ResourceFilterComponent,
    CommissionFileOverviewComponent
  ],
  exports: [CommissionFilesComponent, CommissionStatementsComponent, PaymentFilesComponent, RecoveriesComponent],
})
export class CommissionModule {
}
