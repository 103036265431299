import {Injectable} from '@angular/core';
import {InputField, InputSelect, InputSubmit, Spacer} from '../../../../shared/models/dynamic-form/input-types.model';
import {ResourceService} from '../../../../shared/services/resource/resource.service';
import {Validators} from '@angular/forms';
import {ApiService} from '../../../../core/services/api.service';
import {ValidatorService} from '../../../../shared/services/validator/validator.service';
import {FiscalAttestation, Liability} from '../models/liability.model';
import {Resource} from '../../../../shared/models/resource/resource.model';
import {FormConfiguration} from '../../../../shared/models/dynamic-form/dynamic-form.model';
import {
  EnvironmentCurrencyService
} from '../../../../shared/services/environment-currency/environment-currency.service';
import {ProductService} from '../../../../features/product/services/product.service';
import {map, mergeMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import { PermissionService } from 'apps/brokerage-v1/src/app/core/services/permission.service';

@Injectable({
  providedIn: 'root',
})
export class LiabilityService {
  creditLiabilityTypes = [
    'creditCard',
    'creditLine',
    'mortgageImmovable',
    'mortgageMovable',
    'consumerLoanMovable',
    'consumerLoanImmovable',
    'leasing',
    'otherCredit',
    'investmentCredit',
    'purchaseCredit',
  ];

  constructor(
    private apiService: ApiService,
    private resourceService: ResourceService,
    private validatorService: ValidatorService,
    private currencyService: EnvironmentCurrencyService,
    private productService: ProductService,
    public permissionService: PermissionService,
  ) {
  }

  public getLiabilities(clientId) {
    return this.apiService.get(`/api/clients/${clientId}/liabilities/`);
  }

  public getLiability(clientId, liabilityId) {
    return this.apiService.get(`/api/clients/${clientId}/liabilities/${liabilityId}`);
  }

  public createLiability(clientId, body) {
    return this.apiService.post(`/api/clients/${clientId}/liabilities/`, body);
  }

  public updateLiability(clientId, liabilityId, body) {
    return this.apiService.put(`/api/clients/${clientId}/liabilities/${liabilityId}/`, body);
  }

  public deleteLiability(clientId, liabilityId) {
    return this.apiService.delete(`/api/clients/${clientId}/liabilities/${liabilityId}/`);
  }

  public checkExistingCreditsAtCentralBank(clientId) {
    return this.apiService.get(`/api/clients/${clientId}/nbb/`);
  }

  public refreshExistingCreditsAtCentralBank(clientId, body) {
    return this.apiService.post(`/api/clients/${clientId}/nbb/`, body);
  }
  public calculateFiscalAttestation(body) {
    return this.apiService.post(`/api/calculate/fiscal-attestation/`, body);
  }

  public isCreditLiability(liabilityType: Resource) {
    return this.creditLiabilityTypes.includes(liabilityType.definition);
  }

  public getDialogData(liability: Liability, callBack?: (event?: any) => void) {
    const questions = this.getQuestions(liability);

    const formConfiguration = new FormConfiguration();
    formConfiguration.formControl.questions = questions;

    if (this.isCreditLiability(liability.liabilityType)) {
      formConfiguration.formGroup.validators.push(this.validatorService.irregularityStartDateValidator());
      formConfiguration.formGroup.validators.push(this.validatorService.creditCardBalancetakeoverValidator());
    }

    return {
      title: 'ç.dialog.liabilityDetails.title',
      description: 'ç.dialog.liabilityDetails.description',
      formConfiguration: formConfiguration,
      changeConfiguration: {
        title: `ç.resource.liability-type.${liability.liabilityType.definition}`,
        callBack: callBack
      },
    };
  }

  public getQuestions(liability: Liability) {
    switch (liability.liabilityType.definition) {
      case 'mortgageImmovable':
        return this.getQuestionsMortgageImmovable(liability);
      case 'mortgageMovable':
        return this.getQuestionsMortgageMovable(liability);
      case 'consumerLoanMovable':
        return this.getQuestionsConsumerLoan(liability);
      case 'consumerLoanImmovable':
        return this.getQuestionsConsumerLoan(liability);
      case 'leasing':
        return this.getQuestionsLeasing(liability);
      case 'creditCard':
        return this.getQuestionsCreditCard(liability);
      case 'creditLine':
        return this.getQuestionsCreditLine(liability);
      case 'openCredit':
        return this.getQuestionsOpenCredit(liability);
      case 'investmentCredit':
        return this.getQuestionsInvestmentCredit(liability);
      case 'rent':
        return this.getQuestionsRent(liability);
      case 'dependent':
        return this.getQuestionsDependent(liability);
      case 'alimony':
        return this.getQuestionsAlimony(liability);
      case 'familyDebt':
        return this.getQuestionsFamilyDebt(liability);
      case 'otherCredit':
        return this.getQuestionsOtherCredits(liability);
      case 'purchaseCredit':
        return this.getQuestionsPurchaseCredit(liability);
      case 'other':
        return this.getQuestionsOtherLiability(liability);
      default:
        return this.getQuestionsOther(liability);
    }
  }

  public getQuestionsMortgageImmovable(liability: Liability) {
    const takeOverNoUnknown = this.resourceService.resources['credit-takeover'].filter(resource => resource.definition !== 'unknown');
    const questions = [
      new InputField({
        key: 'initialAmount',
        label: 'ç.question.initialAmount.label',
        value: liability.initialAmount || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, this.validatorService.positiveValidator()],
        order: 1,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'startDate',
        label: 'ç.question.startDate.label',
        value: liability.startDate || '',
        type: 'date',
        class: 'span4',
        validators: [Validators.required, this.validatorService.pastValidator()],
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'interestRate',
        label: 'ç.question.interestRate.label',
        value: liability.interestRate <= 0.2 ? liability.interestRate * 100 : liability.interestRate || 0,
        type: 'number',
        class: 'span4',
        validators: [Validators.max(20), this.validatorService.positiveValidator()],
        required: false,
        order: 1,
        suffix: '%',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'duration',
        label: 'ç.question.duration.label',
        value: liability.duration || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, Validators.max(420), this.validatorService.positiveValidator()],
        order: 1,
        suffix: 'm',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'creditProvider.id',
        label: 'ç.question.creditProvider.label',
        value: (liability.creditProvider && liability.creditProvider.id) || '',
        options: this.resourceService.resources['credit-provider'],
        class: 'span4',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'contractNumber',
        label: 'ç.question.contractNumber.label',
        required: false,
        value: liability.contractNumber || '',
        type: '',
        class: 'span4',
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'regularity.id',
        label: 'ç.question.regularity.label',
        value: (liability.regularity && liability.regularity.id) || '',
        options: this.resourceService.resources['regularity-type'],
        class: 'span5',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'irregularityStartDate',
        label: 'ç.question.irregularityStartDate.label',
        value: liability.irregularityStartDate || null,
        type: 'date',
        class: 'span4',
        validators: [this.validatorService.pastValidator()],
        order: 4,
        required: false,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'takeover.id',
        label: 'ç.question.takeover.label',
        value: (liability.takeover && liability.takeover.id) || '',
        options: takeOverNoUnknown,
        class: 'span3',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'remainingDuration',
        label: 'ç.question.remainingDuration.label',
        value: liability.remainingDuration || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        suffix: 'm',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'earlyRepaymentFee',
        label: 'ç.question.earlyRepaymentFee.label',
        value: liability.earlyRepaymentFee === 0 ? '0.00' : liability.earlyRepaymentFee || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'balance',
        label: 'ç.question.balance.label',
        value: liability.balance || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'monthlyAmount',
        label: 'ç.question.amortization.label',
        value: liability.monthlyAmount || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: true,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'repayableAmount',
        label: 'ç.question.repayableAmount.label',
        value: liability.repayableAmount || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 6,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'aprc',
        label: 'ç.question.aprc.label',
        value: liability.aprc * 100 || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 6,
        required: false,
        suffix: '%',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'deedDate',
        label: 'ç.question.deedDate.label',
        value: liability.deedDate || '',
        type: 'date',
        class: 'span3',
        order: 6,
        required: false,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSubmit({
        key: 'calculateButton',
        label: 'Calculate',
        order: 7,
        class: 'span3',
        iconName: 'uil-sync',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
    ];
    return questions.sort((a, b) => a.order - b.order);
  }

  public getFiscalQuestions(fiscal: FiscalAttestation) {
    const questions = [];
    if(this.permissionService.hasPermission('main.possibilityChangeFiscalityRelatedFields')){
      questions.push(
        new InputField({
          key: 'fiscalAttestationAmount',
          label: 'ç.question.fiscalAttestationAmount.label',
          value: fiscal.fiscalAttestationAmount || '',
          type: 'number',
          class: 'span4',
          required: false,
          disabled: true,
          order: 2,
          prefix: this.currencyService.getSymbol(),
        }),
        new InputField({
          key: 'fiscalAttestationFactor',
          label: 'ç.question.fiscalAttestationFactor.label',
          value: fiscal.fiscalAttestationFactor || 0,
          validators: [Validators.max(100), this.validatorService.positiveValidator()],
          type: 'number',
          class: 'span4',
          required: false,
          order: 3,
          suffix: '%',
          disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
        }),
      )
    }
    return questions.sort((a, b) => a.order - b.order);
  }

  public getImmovableAmountQuestions(fiscal: FiscalAttestation, liability: Liability) {
    const startPercentageImmovable = fiscal.immovableAmount > 0? (Number(fiscal.immovableAmount) / (Number(liability.balance) + Number(liability.earlyRepaymentFee))) * 100 : 100;
    const questions = [];
    questions.push(
        new InputField({
          key: 'immovableAmount',
          label: 'ç.question.immovableAmount.label',
          value:  fiscal.immovableAmount === 0 ? '0.00': fiscal.immovableAmount,
          validators: [this.validatorService.positiveValidator()],
          type: 'number',
          class: 'span4',
          required: false,
          disabled: true,
          order: 1,
          prefix: this.currencyService.getSymbol(),
          specialDisabledStyle: true
        }),
        new InputField({
          key: 'percentageImmovable',
          label: 'ç.question.percentageImmovable.label',
          value: startPercentageImmovable,
          validators: [Validators.max(100), this.validatorService.positiveValidator()],
          type: 'number',
          class: 'span4',
          required: false,
          disabled: this.permissionService.hasPermission('main.auditorExcludeEditing'),
          order: 2,
          suffix: '%'
        }),
        new Spacer({
          class: 'span4',
          order: 3,
        }),
    )
    return questions.sort((a, b) => a.order - b.order);
  }

  public getQuestionsMortgageMovable(liability: Liability) {
    const takeOverNoUnknown = this.resourceService.resources['credit-takeover'].filter(resource => resource.definition !== 'unknown');
    const questions = [
      new InputField({
        key: 'initialAmount',
        label: 'ç.question.initialAmount.label',
        value: liability.initialAmount || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, this.validatorService.positiveValidator()],
        order: 1,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'startDate',
        label: 'ç.question.startDate.label',
        value: liability.startDate || '',
        type: 'date',
        class: 'span4',
        validators: [Validators.required, this.validatorService.pastValidator()],
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'interestRate',
        label: 'ç.question.interestRate.label',
        value: liability.interestRate <= 0.2 ? liability.interestRate * 100 : liability.interestRate || 0,
        type: 'number',
        class: 'span4',
        validators: [Validators.max(20), this.validatorService.positiveValidator()],
        required: false,
        order: 1,
        suffix: '%',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'duration',
        label: 'ç.question.duration.label',
        value: liability.duration || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, Validators.max(420), this.validatorService.positiveValidator()],
        order: 1,
        suffix: 'm',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'creditProvider.id',
        label: 'ç.question.creditProvider.label',
        value: (liability.creditProvider && liability.creditProvider.id) || '',
        options: this.resourceService.resources['credit-provider'],
        class: 'span4',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'contractNumber',
        label: 'ç.question.contractNumber.label',
        required: false,
        value: liability.contractNumber || '',
        type: '',
        class: 'span4',
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'regularity.id',
        label: 'ç.question.regularity.label',
        value: (liability.regularity && liability.regularity.id) || '',
        options: this.resourceService.resources['regularity-type'],
        class: 'span5',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'irregularityStartDate',
        label: 'ç.question.irregularityStartDate.label',
        value: liability.irregularityStartDate || null,
        type: 'date',
        class: 'span4',
        validators: [this.validatorService.pastValidator()],
        order: 4,
        required: false,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'takeover.id',
        label: 'ç.question.takeover.label',
        value: (liability.takeover && liability.takeover.id) || '',
        options: takeOverNoUnknown,
        class: 'span3',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'remainingDuration',
        label: 'ç.question.remainingDuration.label',
        value: liability.remainingDuration || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        suffix: 'm',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'earlyRepaymentFee',
        label: 'ç.question.earlyRepaymentFee.label',
        value: liability.earlyRepaymentFee === 0 ? '0.00' : liability.earlyRepaymentFee || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'balance',
        label: 'ç.question.balance.label',
        value: liability.balance || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'monthlyAmount',
        label: 'ç.question.amortization.label',
        value: liability.monthlyAmount || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: true,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),      
      new InputField({
        key: 'repayableAmount',
        label: 'ç.question.repayableAmount.label',
        value: liability.repayableAmount || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 6,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'aprc',
        label: 'ç.question.aprc.label',
        value: liability.aprc * 100 || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 6,
        required: false,
        suffix: '%',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'deedDate',
        label: 'ç.question.deedDate.label',
        value: liability.deedDate || '',
        type: 'date',
        class: 'span3',
        order: 6,
        required: false,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSubmit({
        label: 'Calculate',
        order: 6,
        class: 'span3',
        iconName: 'uil-sync',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
    ];
    return questions.sort((a, b) => a.order - b.order);
  }

  public getQuestionsConsumerLoan(liability: Liability) {
    const takeOverNoUnknown = this.resourceService.resources['credit-takeover'].filter(resource => resource.definition !== 'unknown');
    const questions = [
      new InputField({
        key: 'initialAmount',
        label: 'ç.question.initialAmount.label',
        value: liability.initialAmount || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, this.validatorService.positiveValidator()],
        order: 1,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'startDate',
        label: 'ç.question.startDate.label',
        value: liability.startDate || '',
        type: 'date',
        class: 'span4',
        validators: [Validators.required, this.validatorService.pastValidator()],
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'interestRate',
        label: 'ç.question.interestRate.label',
        value: liability.interestRate <= 0.2 ? liability.interestRate * 100 : liability.interestRate || 0,
        type: 'number',
        class: 'span4',
        suffix: '%',
        validators: [Validators.max(20), this.validatorService.positiveValidator()],
        required: false,
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'duration',
        label: 'ç.question.duration.label',
        value: liability.duration || '',
        type: 'number',
        class: 'span4',
        suffix: 'm',
        validators: [Validators.required, Validators.max(420), this.validatorService.positiveValidator()],
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'creditProvider.id',
        label: 'ç.question.creditProvider.label',
        value: (liability.creditProvider && liability.creditProvider.id) || '',
        options: this.resourceService.resources['credit-provider'],
        class: 'span4',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'contractNumber',
        label: 'ç.question.contractNumber.label',
        required: false,
        value: liability.contractNumber || '',
        type: '',
        class: 'span4',
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'regularity.id',
        label: 'ç.question.regularity.label',
        value: (liability.regularity && liability.regularity.id) || '',
        options: this.resourceService.resources['regularity-type'],
        class: 'span5',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'irregularityStartDate',
        label: 'ç.question.irregularityStartDate.label',
        value: liability.irregularityStartDate || null,
        type: 'date',
        class: 'span4',
        validators: [this.validatorService.pastValidator()],
        order: 4,
        required: false,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'takeover.id',
        label: 'ç.question.takeover.label',
        value: (liability.takeover && liability.takeover.id) || '',
        options: takeOverNoUnknown,
        class: 'span3',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'remainingDuration',
        label: 'ç.question.remainingDuration.label',
        value: liability.remainingDuration || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        suffix: 'm',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'earlyRepaymentFee',
        label: 'ç.question.earlyRepaymentFee.label',
        value: liability.earlyRepaymentFee === 0 ? '0.00' : liability.earlyRepaymentFee || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'balance',
        label: 'ç.question.balance.label',
        value: liability.balance || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'monthlyAmount',
        label: 'ç.question.amortization.label',
        value: liability.monthlyAmount || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: true,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }), 
      new InputField({
        key: 'repayableAmount',
        label: 'ç.question.repayableAmount.label',
        value: liability.repayableAmount || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 6,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'aprc',
        label: 'ç.question.aprc.label',
        value: liability.aprc * 100 || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 6,
        required: false,
        suffix: '%',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSubmit({
        label: 'Calculate',
        order: 6,
        class: 'span3',
        iconName: 'uil-sync',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
    ];
    return questions.sort((a, b) => a.order - b.order);
  }

  public getQuestionsLeasing(liability: Liability) {
    const takeOverNoUnknown = this.resourceService.resources['credit-takeover'].filter(resource => resource.definition !== 'unknown');
    const questions = [
      new InputField({
        key: 'initialAmount',
        label: 'ç.question.initialAmount.label',
        value: liability.initialAmount || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, this.validatorService.positiveValidator()],
        order: 1,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'startDate',
        label: 'ç.question.startDate.label',
        value: liability.startDate || '',
        type: 'date',
        class: 'span4',
        validators: [Validators.required, this.validatorService.pastValidator()],
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'interestRate',
        label: 'ç.question.interestRate.label',
        value: liability.interestRate <= 0.2 ? liability.interestRate * 100 : liability.interestRate || 0,
        type: 'number',
        class: 'span4',
        suffix: '%',
        validators: [Validators.max(20), this.validatorService.positiveValidator()],
        required: false,
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'duration',
        label: 'ç.question.duration.label',
        value: liability.duration || '',
        type: 'number',
        class: 'span4',
        suffix: 'm',
        validators: [Validators.required, Validators.max(420), this.validatorService.positiveValidator()],
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'creditProvider.id',
        label: 'ç.question.creditProvider.label',
        value: (liability.creditProvider && liability.creditProvider.id) || '',
        options: this.resourceService.resources['credit-provider'],
        class: 'span4',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'contractNumber',
        label: 'ç.question.contractNumber.label',
        required: false,
        value: liability.contractNumber || '',
        type: '',
        class: 'span4',
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'regularity.id',
        label: 'ç.question.regularity.label',
        value: (liability.regularity && liability.regularity.id) || '',
        options: this.resourceService.resources['regularity-type'],
        class: 'span5',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'irregularityStartDate',
        label: 'ç.question.irregularityStartDate.label',
        value: liability.irregularityStartDate || null,
        type: 'date',
        class: 'span4',
        validators: [this.validatorService.pastValidator()],
        order: 4,
        required: false,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'takeover.id',
        label: 'ç.question.takeover.label',
        value: (liability.takeover && liability.takeover.id) || '',
        options: takeOverNoUnknown,
        class: 'span3',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'remainingDuration',
        label: 'ç.question.remainingDuration.label',
        value: liability.remainingDuration || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        suffix: 'm',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'earlyRepaymentFee',
        label: 'ç.question.earlyRepaymentFee.label',
        value: liability.earlyRepaymentFee === 0 ? '0.00' : liability.earlyRepaymentFee || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'balance',
        label: 'ç.question.balance.label',
        value: liability.balance || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'monthlyAmount',
        label: 'ç.question.amortization.label',
        value: liability.monthlyAmount || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: true,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'repayableAmount',
        label: 'ç.question.repayableAmount.label',
        value: liability.repayableAmount || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 6,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'aprc',
        label: 'ç.question.aprc.label',
        value: liability.aprc * 100 || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 6,
        required: false,
        suffix: '%',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSubmit({
        label: 'Calculate',
        order: 6,
        class: 'span3',
        iconName: 'uil-sync',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
    ];
    return questions.sort((a, b) => a.order - b.order);
  }

  public getQuestionsCreditCard(liability: Liability) {
    const takeOverNoUnknown = this.resourceService.resources['credit-takeover'].filter(resource => resource.definition !== 'unknown');
    const questions = [
      new InputField({
        key: 'amount',
        label: 'ç.question.monthlyLimit.label',
        value: liability.amount || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, this.validatorService.positiveValidator()],
        order: 1,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'startDate',
        label: 'ç.question.startDate.label',
        value: liability.startDate || '',
        type: 'date',
        class: 'span4',
        validators: [Validators.required, this.validatorService.pastValidator()],
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'creditProvider.id',
        label: 'ç.question.creditProvider.label',
        value: (liability.creditProvider && liability.creditProvider.id) || '',
        options: this.resourceService.resources['credit-provider'],
        class: 'span4',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'regularity.id',
        label: 'ç.question.regularity.label',
        value: (liability.regularity && liability.regularity.id) || '',
        options: this.resourceService.resources['regularity-type'],
        class: 'span5',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'irregularityStartDate',
        label: 'ç.question.irregularityStartDate.label',
        value: liability.irregularityStartDate || null,
        type: 'date',
        class: 'span4',
        validators: [this.validatorService.pastValidator()],
        order: 4,
        required: false,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'takeover.id',
        label: 'ç.question.takeover.label',
        value: (liability.takeover && liability.takeover.id) || '',
        options: takeOverNoUnknown,
        class: 'span3',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'contractNumber',
        label: 'ç.question.contractNumber.label',
        required: false,
        value: liability.contractNumber || '',
        type: '',
        class: 'span4',
        order: 6,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'balance',
        label: 'ç.question.balance.label',
        value: liability.balance || 0,
        type: 'number',
        class: 'span4',
        validators: [this.validatorService.positiveValidator()],
        required: false,
        order: 7,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
    ];
    return questions.sort((a, b) => a.order - b.order);
  }

  public getQuestionsCreditLine(liability: Liability) {
    const takeOverNoUnknown = this.resourceService.resources['credit-takeover'].filter(resource => resource.definition !== 'unknown');
    const questions = [
      new InputField({
        key: 'amount',
        label: 'ç.question.monthlyLimit.label',
        value: liability.amount || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, this.validatorService.positiveValidator()],
        order: 1,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'startDate',
        label: 'ç.question.startDate.label',
        value: liability.startDate || '',
        type: 'date',
        class: 'span4',
        validators: [Validators.required, this.validatorService.pastValidator()],
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'creditProvider.id',
        label: 'ç.question.creditProvider.label',
        value: (liability.creditProvider && liability.creditProvider.id) || '',
        options: this.resourceService.resources['credit-provider'],
        class: 'span4',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'regularity.id',
        label: 'ç.question.regularity.label',
        value: (liability.regularity && liability.regularity.id) || '',
        options: this.resourceService.resources['regularity-type'],
        class: 'span5',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'irregularityStartDate',
        label: 'ç.question.irregularityStartDate.label',
        value: liability.irregularityStartDate || null,
        type: 'date',
        class: 'span4',
        validators: [this.validatorService.pastValidator()],
        order: 4,
        required: false,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'takeover.id',
        label: 'ç.question.takeover.label',
        value: (liability.takeover && liability.takeover.id) || '',
        options: takeOverNoUnknown,
        class: 'span3',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'contractNumber',
        label: 'ç.question.contractNumber.label',
        required: false,
        value: liability.contractNumber || '',
        type: '',
        class: 'span4',
        order: 6,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'balance',
        label: 'ç.question.balance.label',
        value: liability.balance || 0,
        type: 'number',
        class: 'span4',
        validators: [this.validatorService.positiveValidator()],
        required: false,
        order: 7,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
    ];
    return questions.sort((a, b) => a.order - b.order);
  }

  public getQuestionsOpenCredit(liability: Liability) {
    const takeOverNoUnknown = this.resourceService.resources['credit-takeover'].filter(resource => resource.definition !== 'unknown');
    const questions = [
      new InputField({
        key: 'initialAmount',
        label: 'ç.question.initialAmount.label',
        value: liability.initialAmount || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, this.validatorService.positiveValidator()],
        order: 1,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'startDate',
        label: 'ç.question.startDate.label',
        value: liability.startDate || '',
        type: 'date',
        class: 'span4',
        validators: [Validators.required, this.validatorService.pastValidator()],
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'interestRate',
        label: 'ç.question.interestRate.label',
        value: liability.interestRate <= 0.2 ? liability.interestRate * 100 : liability.interestRate || 0,
        type: 'number',
        class: 'span4',
        validators: [Validators.max(20), this.validatorService.positiveValidator()],
        required: false,
        order: 1,
        suffix: '%',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'duration',
        label: 'ç.question.duration.label',
        value: liability.duration || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, Validators.max(420), this.validatorService.positiveValidator()],
        order: 1,
        suffix: 'm',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'creditProvider.id',
        label: 'ç.question.creditProvider.label',
        value: (liability.creditProvider && liability.creditProvider.id) || '',
        options: this.resourceService.resources['credit-provider'],
        class: 'span4',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'contractNumber',
        label: 'ç.question.contractNumber.label',
        required: false,
        value: liability.contractNumber || '',
        type: '',
        class: 'span4',
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'regularity.id',
        label: 'ç.question.regularity.label',
        value: (liability.regularity && liability.regularity.id) || '',
        options: this.resourceService.resources['regularity-type'],
        class: 'span5',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'irregularityStartDate',
        label: 'ç.question.irregularityStartDate.label',
        value: liability.irregularityStartDate || null,
        type: 'date',
        class: 'span4',
        validators: [this.validatorService.pastValidator()],
        order: 4,
        required: false,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'takeover.id',
        label: 'ç.question.takeover.label',
        value: (liability.takeover && liability.takeover.id) || '',
        options: takeOverNoUnknown,
        class: 'span3',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'balance',
        label: 'ç.question.balance.label',
        value: liability.balance || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'monthlyAmount',
        label: 'ç.question.amortization.label',
        value: liability.monthlyAmount || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: true,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSubmit({
        label: 'Calculate',
        order: 6,
        class: 'span3',
        iconName: 'uil-sync',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
    ];
    return questions.sort((a, b) => a.order - b.order);
  }

  public getQuestionsInvestmentCredit(liability: Liability) {
    const takeOverNoUnknown = this.resourceService.resources['credit-takeover'].filter(resource => resource.definition !== 'unknown');
    const questions = [
      new InputField({
        key: 'initialAmount',
        label: 'ç.question.initialAmount.label',
        value: liability.initialAmount || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, this.validatorService.positiveValidator()],
        order: 1,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'startDate',
        label: 'ç.question.startDate.label',
        value: liability.startDate || '',
        type: 'date',
        class: 'span4',
        validators: [Validators.required, this.validatorService.pastValidator()],
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'interestRate',
        label: 'ç.question.interestRate.label',
        value: liability.interestRate <= 0.2 ? liability.interestRate * 100 : liability.interestRate || 0,
        type: 'number',
        class: 'span4',
        validators: [Validators.max(20), this.validatorService.positiveValidator()],
        required: false,
        order: 1,
        suffix: '%',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'duration',
        label: 'ç.question.duration.label',
        value: liability.duration || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, Validators.max(420), this.validatorService.positiveValidator()],
        order: 1,
        suffix: 'm',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'creditProvider.id',
        label: 'ç.question.creditProvider.label',
        value: (liability.creditProvider && liability.creditProvider.id) || '',
        options: this.resourceService.resources['credit-provider'],
        class: 'span4',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'contractNumber',
        label: 'ç.question.contractNumber.label',
        required: false,
        value: liability.contractNumber || '',
        type: '',
        class: 'span4',
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'regularity.id',
        label: 'ç.question.regularity.label',
        value: (liability.regularity && liability.regularity.id) || '',
        options: this.resourceService.resources['regularity-type'],
        class: 'span5',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'irregularityStartDate',
        label: 'ç.question.irregularityStartDate.label',
        value: liability.irregularityStartDate || null,
        type: 'date',
        class: 'span4',
        validators: [this.validatorService.pastValidator()],
        order: 4,
        required: false,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'takeover.id',
        label: 'ç.question.takeover.label',
        value: (liability.takeover && liability.takeover.id) || '',
        options: takeOverNoUnknown,
        class: 'span3',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'remainingDuration',
        label: 'ç.question.remainingDuration.label',
        value: liability.remainingDuration || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        suffix: 'm',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'earlyRepaymentFee',
        label: 'ç.question.earlyRepaymentFee.label',
        value: liability.earlyRepaymentFee === 0 ? '0.00' : liability.earlyRepaymentFee || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'balance',
        label: 'ç.question.balance.label',
        value: liability.balance || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'monthlyAmount',
        label: 'ç.question.amortization.label',
        value: liability.monthlyAmount || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: true,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'repayableAmount',
        label: 'ç.question.repayableAmount.label',
        value: liability.repayableAmount || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 6,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'aprc',
        label: 'ç.question.aprc.label',
        value: liability.aprc * 100 || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 6,
        required: false,
        suffix: '%',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSubmit({
        label: 'Calculate',
        order: 6,
        class: 'span3',
        iconName: 'uil-sync',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
    ];
    return questions.sort((a, b) => a.order - b.order);
  }

  public getQuestionsRent(liability: Liability) {
    const questions = [
      new InputField({
        key: 'startDate',
        label: 'ç.question.startDate.label',
        value: liability.startDate || '',
        type: 'date',
        class: 'span4',
        validators: [Validators.required, this.validatorService.pastValidator()],
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'amount',
        label: 'ç.question.amount.label',
        value: liability.amount || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, this.validatorService.positiveValidator()],
        order: 1,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'goneAfterRequest',
        label: 'ç.question.goneAfterRequest.label',
        value: liability.goneAfterRequest == null ? '' : liability.goneAfterRequest,
        class: 'span4',
        options: [
          {
            key: 'ç.question.goneAfterRequest.option.yes',
            id: true,
          },
          {
            key: 'ç.question.goneAfterRequest.option.no',
            id: false,
          },
        ],
        searchable: true,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
    ];
    return questions.sort((a, b) => a.order - b.order);
  }

  public getQuestionsDependent(liability: Liability) {
    const questions = [
      new InputField({
        key: 'amount',
        label: 'ç.question.amount.label',
        value: liability.amount || '',
        type: 'number',
        class: 'span12',
        validators: [Validators.required, this.validatorService.positiveValidator()],
        order: 1,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
    ];
    return questions.sort((a, b) => a.order - b.order);
  }

  public getQuestionsAlimony(liability: Liability) {
    const questions = [
      new InputField({
        key: 'amount',
        label: 'ç.question.amount.label',
        value: liability.amount || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, this.validatorService.positiveValidator()],
        order: 1,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'startDate',
        label: 'ç.question.startDate.label',
        value: liability.startDate || '',
        type: 'date',
        class: 'span4',
        validators: [Validators.required, this.validatorService.pastValidator()],
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'endDate',
        label: 'ç.question.endDate.label',
        value: liability.endDate || '',
        type: 'date',
        class: 'span4',
        validators: [Validators.required, this.validatorService.futureValidator()],
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
    ];
    return questions.sort((a, b) => a.order - b.order);
  }

  public getQuestionsFamilyDebt(liability: Liability) {
    const takeOverNoUnknown = this.resourceService.resources['credit-takeover'].filter(resource => resource.definition !== 'unknown');
    const questions = [
      new InputField({
        key: 'amount',
        label: 'ç.question.amount.label',
        value: liability.amount || '',
        type: 'number',
        class: 'span6',
        validators: [Validators.required, this.validatorService.positiveValidator()],
        order: 1,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'takeover.id',
        label: 'ç.question.takeover.label',
        value: (liability.takeover && liability.takeover.id) || '',
        options: takeOverNoUnknown,
        class: 'span6',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
    ];
    return questions.sort((a, b) => a.order - b.order);
  }

  public getQuestionsOtherCredits(liability: Liability) {
    const takeOverNoUnknown = this.resourceService.resources['credit-takeover'].filter(resource => resource.definition !== 'unknown');
    const questions = [
      new InputField({
        key: 'initialAmount',
        label: 'ç.question.initialAmount.label',
        value: liability.initialAmount || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, this.validatorService.positiveValidator()],
        order: 1,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'startDate',
        label: 'ç.question.startDate.label',
        value: liability.startDate || '',
        type: 'date',
        class: 'span4',
        validators: [Validators.required, this.validatorService.pastValidator()],
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'interestRate',
        label: 'ç.question.interestRate.label',
        value: liability.interestRate <= 0.2 ? liability.interestRate * 100 : liability.interestRate || 0,
        type: 'number',
        class: 'span4',
        validators: [Validators.max(20), this.validatorService.positiveValidator()],
        required: false,
        order: 1,
        suffix: '%',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'duration',
        label: 'ç.question.duration.label',
        value: liability.duration || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, Validators.max(420), this.validatorService.positiveValidator()],
        order: 1,
        suffix: 'm',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'creditProvider.id',
        label: 'ç.question.creditProvider.label',
        value: (liability.creditProvider && liability.creditProvider.id) || '',
        options: this.resourceService.resources['credit-provider'],
        class: 'span4',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'contractNumber',
        label: 'ç.question.contractNumber.label',
        required: false,
        value: liability.contractNumber || '',
        type: '',
        class: 'span4',
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'regularity.id',
        label: 'ç.question.regularity.label',
        value: (liability.regularity && liability.regularity.id) || '',
        options: this.resourceService.resources['regularity-type'],
        class: 'span5',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'irregularityStartDate',
        label: 'ç.question.irregularityStartDate.label',
        value: liability.irregularityStartDate || null,
        type: 'date',
        class: 'span4',
        validators: [this.validatorService.pastValidator()],
        order: 4,
        required: false,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'takeover.id',
        label: 'ç.question.takeover.label',
        value: (liability.takeover && liability.takeover.id) || '',
        options: takeOverNoUnknown,
        class: 'span3',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'remainingDuration',
        label: 'ç.question.remainingDuration.label',
        value: liability.remainingDuration || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        suffix: 'm',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'earlyRepaymentFee',
        label: 'ç.question.earlyRepaymentFee.label',
        value: liability.earlyRepaymentFee === 0 ? '0.00' : liability.earlyRepaymentFee || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'balance',
        label: 'ç.question.balance.label',
        value: liability.balance || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'monthlyAmount',
        label: 'ç.question.amortization.label',
        value: liability.monthlyAmount || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: true,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'repayableAmount',
        label: 'ç.question.repayableAmount.label',
        value: liability.repayableAmount || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 6,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'aprc',
        label: 'ç.question.aprc.label',
        value: liability.aprc * 100 || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 6,
        required: false,
        suffix: '%',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSubmit({
        label: 'Calculate',
        order: 6,
        class: 'span3',
        iconName: 'uil-sync',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
    ];
    return questions.sort((a, b) => a.order - b.order);
  }

  public getQuestionsPurchaseCredit(liability: Liability) {
    const takeOverNoUnknown = this.resourceService.resources['credit-takeover'].filter(resource => resource.definition !== 'unknown');
    const questions = [
      new InputField({
        key: 'initialAmount',
        label: 'ç.question.initialAmount.label',
        value: liability.initialAmount || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, this.validatorService.positiveValidator()],
        order: 1,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'startDate',
        label: 'ç.question.startDate.label',
        value: liability.startDate || '',
        type: 'date',
        class: 'span4',
        validators: [Validators.required, this.validatorService.pastValidator()],
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'interestRate',
        label: 'ç.question.interestRate.label',
        value: liability.interestRate <= 0.2 ? liability.interestRate * 100 : liability.interestRate || 0,
        type: 'number',
        class: 'span4',
        validators: [Validators.max(20), this.validatorService.positiveValidator()],
        required: false,
        order: 1,
        suffix: '%',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'duration',
        label: 'ç.question.duration.label',
        value: liability.duration || '',
        type: 'number',
        class: 'span4',
        validators: [Validators.required, Validators.max(420), this.validatorService.positiveValidator()],
        order: 1,
        suffix: 'm',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'creditProvider.id',
        label: 'ç.question.creditProvider.label',
        value: (liability.creditProvider && liability.creditProvider.id) || '',
        options: this.resourceService.resources['credit-provider'],
        class: 'span4',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'contractNumber',
        label: 'ç.question.contractNumber.label',
        required: false,
        value: liability.contractNumber || '',
        type: '',
        class: 'span4',
        order: 1,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'regularity.id',
        label: 'ç.question.regularity.label',
        value: (liability.regularity && liability.regularity.id) || '',
        options: this.resourceService.resources['regularity-type'],
        class: 'span5',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'irregularityStartDate',
        label: 'ç.question.irregularityStartDate.label',
        value: liability.irregularityStartDate || null,
        type: 'date',
        class: 'span4',
        validators: [this.validatorService.pastValidator()],
        order: 4,
        required: false,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'takeover.id',
        label: 'ç.question.takeover.label',
        value: (liability.takeover && liability.takeover.id) || '',
        options: takeOverNoUnknown,
        class: 'span3',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'remainingDuration',
        label: 'ç.question.remainingDuration.label',
        value: liability.remainingDuration || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        suffix: 'm',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'earlyRepaymentFee',
        label: 'ç.question.earlyRepaymentFee.label',
        value: liability.earlyRepaymentFee === 0 ? '0.00' : liability.earlyRepaymentFee || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'balance',
        label: 'ç.question.balance.label',
        value: liability.balance || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'monthlyAmount',
        label: 'ç.question.amortization.label',
        value: liability.monthlyAmount || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 5,
        required: true,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'repayableAmount',
        label: 'ç.question.repayableAmount.label',
        value: liability.repayableAmount || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 6,
        required: false,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'aprc',
        label: 'ç.question.aprc.label',
        value: liability.aprc * 100 || '',
        type: 'number',
        class: 'span3',
        validators: [this.validatorService.positiveValidator()],
        order: 6,
        required: false,
        suffix: '%',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSubmit({
        label: 'Calculate',
        order: 6,
        class: 'span3',
        iconName: 'uil-sync',
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
    ];
    return questions.sort((a, b) => a.order - b.order);
  }

  public getQuestionsOther(liability: Liability) {
    const takeOverNoUnknown = this.resourceService.resources['credit-takeover'].filter(resource => resource.definition !== 'unknown');
    const questions = [
      new InputField({
        key: 'amount',
        label: 'ç.question.amount.label',
        value: liability.amount || '',
        type: 'number',
        class: 'span6',
        validators: [Validators.required, this.validatorService.positiveValidator()],
        order: 1,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputSelect({
        key: 'takeover.id',
        label: 'ç.question.takeover.label',
        value: (liability.takeover && liability.takeover.id) || '',
        options: takeOverNoUnknown,
        class: 'span6',
        validators: [Validators.required],
        searchable: true,
        order: 4,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
    ];
    return questions.sort((a, b) => a.order - b.order);
  }

  public getQuestionsOtherLiability(liability: Liability) {
    const questions = [
      new InputField({
        key: 'amount',
        label: 'ç.question.amount.label',
        value: liability.amount || '',
        type: 'number',
        class: 'span6',
        validators: [Validators.required, this.validatorService.positiveValidator()],
        order: 1,
        prefix: this.currencyService.getSymbol(),
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
      new InputField({
        key: 'nature',
        label: 'ç.question.nature.label',
        value: liability.nature || '',
        type: 'string',
        class: 'span6',
        validators: [this.validatorService.maxSimbolValidator(128)],
        order: 2,
        disabled: this.permissionService.hasPermission('main.auditorExcludeEditing')
      }),
    ];
    return questions.sort((a, b) => a.order - b.order);
  }

  public actualiseCredit(credit: Liability): Observable<Liability | null> {
    if (!credit) {
      return of(null);
    }
    const updatedCredit = JSON.parse(JSON.stringify(credit));
    // Assume updates are annuities
    updatedCredit.loanType = {id: this.resourceService.getResourceInstance('loan-type', 'definition', 'mensuality').id};
    updatedCredit.amount = updatedCredit.initialAmount;
    updatedCredit.amortization = updatedCredit.monthlyAmount;

    if (!updatedCredit.amortization) {
      delete updatedCredit.amortization;
    }
    if (!updatedCredit.interestRate) {
      delete updatedCredit.interestRate;
    } else {
      updatedCredit.interestRate = updatedCredit.interestRate / 100;
    }
    return this.productService.calculateAmortization(updatedCredit).pipe(
      map(result => {
        updatedCredit.balance = result.balance;
        updatedCredit.interestRate = result.interestRate;
        updatedCredit.monthlyAmount = result.amortization;
        const startDateToArray = updatedCredit.startDate.split('-');
        const today = new Date();
        const startDate = new Date(startDateToArray[0], startDateToArray[1] - 1, startDateToArray[2]);
        const currentDuration = 12*(today.getFullYear() - startDate.getFullYear()) + (today.getMonth() - startDate.getMonth()) + ((today.getDate() - startDate.getDate()) < 0 ? -1 : 0);
        updatedCredit.remainingDuration = updatedCredit.duration - currentDuration;
        updatedCredit.repayableAmount = updatedCredit.remainingDuration * updatedCredit.monthlyAmount;
        updatedCredit.earlyRepaymentFee = result.earlyRepaymentFee ?? updatedCredit.earlyRepaymentFee;
        updatedCredit.aprc = result.aprc || updatedCredit.aprc;
        return updatedCredit;
      }),
    );
  }
}
